$orange: #ea580c; //234, 88, 12
$darkblue: #3d405b;
$black: #000;

.logoHolder {
  position: absolute;
  z-index: 300;
  transition: opacity 1s;
  animation-duration: 2s;
  animation-name: slidein;
  top: -20px;
  .logo {
    svg {
      transform: unset !important;
    }
  }
  &.smaller {
    max-width: 100%;
    top: -70px;
    left: -5px;
    transform: scale(0.7);
    @media screen and (max-height: 700px) {
      top: -70px;
      left: -10px;
    }
    //transform: translateX(-50%);
  }
  @keyframes slidein {
    from {
      top: 400px;
    }
    to {
      top: -40px;
    }
  }
}

.news {
  width: 100vw;
  height: -webkit-fill-available;
  height: -moz-available;
  height: fill-available;
  display: flex;
  overflow: hidden;
  flex: 1;
  background: url('../assets/bg2.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;

    z-index: 1;
    pointer-events: none;
    background: rgb(0, 0, 0);
    background: linear-gradient(140deg, rgba(0, 0, 0, 0) 40%, rgba(17, 2, 2, 1) 80%);
  }
  .quizHolder {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: -webkit-fill-available;
    height: -moz-available;
    height: fill-available;
    width: 100%;
    max-width: 450px;
    position: relative;
    margin: auto;
    z-index: 100;
    padding-top: 40px;
    .nextBtn {
      position: absolute;
      bottom: 20px;
      padding: 10px 40px;
      color: white;
      font-weight: 900;
      background: $orange;
      border-radius: 5px;
      -webkit-user-select: none; /* Safari */
      -ms-user-select: none; /* IE 10 and IE 11 */
      user-select: none; /* Standard syntax */
      transition: opacity 0.5s;
      animation-duration: 1s;
      animation-name: fadein;

      @keyframes fadein {
        from {
          opacity: 0;
        }

        to {
          opacity: 1;
        }
      }
    }
    .card {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-radius: 20px;
      flex-direction: column;
      text-align: center;
      color: black;
      width: 85%;
      position: absolute;
      overflow: hidden;
      background: rgba(255, 255, 255, 1);

      .subline {
        font-weight: bold;
        text-decoration: underline;
      }
      .alldone {
        color: white;
        width: 80%;
        margin: auto;
        img {
          width: 50%;
          margin-bottom: 10px;
        }
        p {
          margin-top: 20px;
          line-height: 1rem;
          font-size: 1rem;
        }
      }
      &.black {
        background: rgba(0, 0, 0, 1);
        .cardQuestion {
          padding-top: 20px;
          background: rgba(0, 0, 0, 1);
          width: 100%;

          .btn {
            cursor: pointer;
            color: white;
            -webkit-user-select: none; /* Safari */
            -ms-user-select: none; /* IE 10 and IE 11 */
            user-select: none; /* Standard syntax */
            padding: 15px 40px;
            background: $orange;
            opacity: 0.3;
            border-radius: 5px;
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
            width: unset;
            flex: 1;
            margin: 20px auto 0 auto;
            &.active {
              opacity: 1;
            }
          }
          .sendit {
            border-radius: 5px;
            border-width: 0;
            padding: 5px 40px;
            margin-bottom: 20px;
          }
        }
        input[type='text'] {
          margin: 10px 0px;
          width: 100%;
          color: white;
          font-size: 0.875rem !important;
          line-height: 1.25rem !important;
          padding: 0.75rem 1rem;
          background-color: black;
          border-radius: 0.375rem;
          border: 1px solid $orange;
          max-width: 260px;
          outline: 2px solid transparent;
          &.notvalid {
            color: red;
          }
        }
        .checkboxHolder {
          color: white;
          width: 70%;
          margin: 10px auto;
          display: flex;
          align-items: flex-start;
          text-align: left;
          position: relative;
          padding: 0.75rem 1rem;
          max-width: 298px;
          &.letter {
            margin-bottom: 30px;
          }
          p {
            margin: unset;
            margin-left: 40px;
            font-size: 0.8rem;
            line-height: 0.9rem;
          }
          input[type='checkbox'] {
            position: absolute;
            appearance: none;
            background-color: #000;
            margin: 0;
            font: inherit;
            width: 30px;
            height: 30px;
            border: 1px solid $orange;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;
            &:before {
              content: '';
              width: 20px;
              height: 20px;
              transform: scale(0);
              border-radius: 2px;
              transition: 120ms transform ease-in-out;
              box-shadow: inset 20px 20px $orange;
            }
            &:checked::before {
              transform: scale(1);
            }
          }
        }
      }
      &.locked {
        -webkit-filter: blur(5px);
        -moz-filter: blur(5px);
        -o-filter: blur(5px);
        -ms-filter: blur(5px);
        filter: blur(5px);
        transform: scale(0.9) !important;
      }
      .pic {
        flex: 0.5;
        width: 100%;
        min-height: 150px;
        display: flex;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        align-items: center;
        justify-content: center;
        &.npic {
          min-height: 100px;
        }
        p {
          font-size: 2rem;
          line-height: 0;
          padding: 0;
          margin: 0;
          font-weight: bold;
          color: white;
          text-shadow:
            0px 0px 20px #000,
            0px 0px 20px #000;
          color: white;
          -webkit-user-select: none; /* Safari */
          -ms-user-select: none; /* IE 10 and IE 11 */
          user-select: none; /* Standard syntax */
        }
        &.end {
          display: flex;
          flex-direction: column;
          img {
            max-width: 60%;
            margin-bottom: -20px;
          }
          p {
            font-size: 1rem;
            line-height: 1.5rem;
            &.small {
              margin-top: 10px;
              font-size: 1.1rem;
              line-height: 0.9rem;
            }
          }
        }
      }
      h2 {
        font-size: 1.1rem;
        line-height: 1.1rem;
        padding-left: 20px;
        padding-right: 20px;
        margin: 30px 0px;
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */
      }
      .cardQuestion {
        flex: 0.6;
        width: 100%;
        min-height: 200px;
        background: white;
      }
      .btns {
        width: 100%;
      }
      .btn {
        width: 100%;
        padding: 15px 0px;
        position: relative;
        border-radius: 0;
        color: white;
        background: rgba(0, 0, 0, 0.3);
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */
        &:nth-child(even) {
          background: rgba(0, 0, 0, 0.4);
        }
        &.gray {
          background: #d3d3d3;
          &:nth-child(even) {
            background: #cccccc;
          }
        }
        &.selected {
          color: white;
          font-weight: bold;
          background: rgba(234, 88, 12, 1);
        }
      }
    }
  }

  .start {
    z-index: 100;
    width: 100%;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;

    .content-holder {
      opacity: 0;
      transition: opacity 0.5s;
      &.show {
        opacity: 1;
      }
      justify-content: center;
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 0px 20px;
      .content {
        display: flex;
        align-items: center;
        flex-direction: column;
        max-width: 400px;
        background: rgba(0, 0, 0, 0.9);
        padding: 40px 30px;
        border-radius: 20px;
        color: white;
      }
      p {
        font-size: 1rem;
        line-height: 1.1rem;
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */
      }
      h1 {
        width: unset;
        margin: unset;
        margin-bottom: 10px;
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */
      }
    }
    .btn {
      margin-top: 20px;
      cursor: pointer;
      align-items: center;
      font-weight: 700;
      text-align: center;
      border-radius: 5px;
      color: white;
      background: $orange;
      padding: 10px 50px;
      font-size: 0.8rem;
      width: fit-content;

      -webkit-user-select: none; /* Safari */
      -ms-user-select: none; /* IE 10 and IE 11 */
      user-select: none; /* Standard syntax */
    }
  }
}

.Desk {
  .logoHolder {
    max-width: 400px;
    left: 50%;
    transform: translateX(-50%);
    margin-left: -15px;
    animation: unset;
    &.smaller {
      max-width: 400px;
      transform: scale(1) translateX(-50%);
    }
  }
  .news {
    .quizHolder {
      .card {
        .pic {
          &.npic {
            min-height: 150px !important;
          }
        }
      }
    }
  }
  .quiz {
    justify-content: center;

    .card {
      max-width: 400px;
    }
  }
}
