$orange: rgb(234, 88, 12);
.policy {
  padding: 50px 0px;
  color: black;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  overflow-x: hidden;
  a,
  a:visited,
  a:hover {
    color: $orange;
  }
  .content {
    width: 80%;
    max-width: 700px;
    display: flex;
    flex-direction: column;
  }
}
