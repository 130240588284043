$orange: rgb(234, 88, 12);
$darkblue: #3d405b;
$black: #000;

.tc {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  z-index: 999;
  .tc_content {
    display: flex;
    padding: 0px;
    padding-top: 20px;
    background: white;
    height: 550px;
    width: 320px;
    position: absolute;
    color: black;
    flex-direction: column;
    flex: 1;
    .close {
      position: absolute;
      right: 10px;
      top: -5px;
      z-index: 200;
    }
    .hcontent {
      display: flex;
      flex-direction: column;
      padding: 0px 30px;
      overflow: hidden;
      overflow-y: scroll;
      padding-bottom: 100px;
      font-size: 0.8rem;
      line-height: 1rem;
      a {
        color: $orange;
        font-weight: bold;
      }
      ul {
        margin-left: -15px;
        li {
          margin-bottom: 10px;
        }
      }
      &:-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
    h3 {
      width: 100%;
      text-align: center;
      margin: 10px 0px;
    }
    p {
      font-size: 0.8rem;
      line-height: 1rem;
      margin: 0;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    b {
      font-size: 1rem;
      line-height: 1rem;
      margin-bottom: 20px;
    }
    &:after,
    &:before {
      content: '';
      width: 100%;
      height: 50px;
      position: absolute;
      display: block;
      background: url('../assets/topbar.svg');
      background-repeat: no-repeat;
      background-position: center bottom;
      background-size: auto;
      top: -49px;
      left: 0px;
      z-index: 50;
    }
    &:after {
      top: unset;
      bottom: -49px;
      transform: scaleY(-1);
    }
  }
}
