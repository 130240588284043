$orange: rgb(234, 88, 12);
$darkblue: #3d405b;
$black: #000;

.confetti {
  position: absolute !important;
  z-index: 1;
}
.qr {
  width: 100vw;
  min-height: -webkit-fill-available;
  min-height: -moz-available;
  min-height: fill-available;
  overflow: hidden;
  flex: 1;
  /*background: rgb(234,88,12);
  background: linear-gradient(167deg, rgba(234,88,12,1) 27%, rgba(61,64,91,1) 89%);*/
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  display: flex;
  flex-direction: column;
  background: url('../assets/bg.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;

  .topBar {
    position: absolute;
    width: 100%;
    height: 60px;
    top: 0px;
    left: 0px;
    .miniLogo {
      position: absolute;
      top: 20px;
      left: 20px;
      width: 200px;
    }
    .questions {
      position: absolute;
      top: 10px;
      right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:active {
        opacity: 0.6;
      }
    }
  }
  .allDone {
    display: flex;
    padding: 0px;
    background: #000;
    position: absolute;
    top: 50%;
    margin: auto;
    z-index: 999;
    width: 80%;
    max-width: 380px;
    color: black;
    flex-direction: column;
    flex: 1 1;
    opacity: 0;
    display: none;
    align-items: center;
    transform: translateY(-33%);
    .formDone {
      color: white;
      width: 80%;
      margin: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      img {
        width: 50%;
        margin-bottom: 10px;
      }
      p {
        margin-top: 20px;
        line-height: 1rem;
        font-size: 1rem;
      }
    }
    .btn {
      cursor: pointer;
      color: white;
      -webkit-user-select: none; /* Safari */
      -ms-user-select: none; /* IE 10 and IE 11 */
      user-select: none; /* Standard syntax */
      padding: 5px 40px;
      background: $orange;
      opacity: 0.3;
      border-radius: 5px;
      border-width: 0px;
      width: unset;
      flex: 1;
      margin: 20px auto 0 auto;
      &.active {
        opacity: 1;
      }
    }
    h2 {
      margin: 0;
      color: white;
      padding: 0px 20px;
      font-size: 1rem;
      font-weight: bold;
      text-align: center;
    }
    align-items: center;
    .hcontent {
      display: flex;
      flex-direction: column;
      padding: 0px 30px;
      overflow: hidden;
      overflow-y: scroll;
      padding-bottom: 100px;
      &:-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
    h3 {
      width: 100%;
      text-align: center;
      margin: 10px 0px;
    }
    p {
      font-size: 1rem;
      line-height: 1.1rem;
      margin: 0;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    b {
      font-size: 1rem;
      line-height: 1rem;
      margin-bottom: 20px;
    }
    &:after,
    &:before {
      content: '';
      width: 100%;
      height: 50px;
      position: absolute;
      display: block;
      background: url('../assets/topbar_black.svg');
      background-repeat: no-repeat;
      background-position: center bottom;
      background-size: auto;
      top: -48px;
      left: 0px;
      z-index: 50;
    }
    &:after {
      top: unset;
      bottom: -49px;
      transform: scaleY(-1);
    }
    .subline {
      font-weight: bold;
      text-decoration: underline;
    }
    input[type='text'] {
      margin: 10px 0px;
      width: 100%;
      color: white;
      font-size: 0.875rem !important;
      line-height: 1.25rem !important;
      padding: 0.75rem 1rem;
      background-color: black;
      border-radius: 0.375rem;
      border: 1px solid $orange;
      max-width: 260px;
      outline: 2px solid transparent;
      &.notvalid {
        color: red;
      }
    }
    .checkboxHolder {
      color: white;
      width: 100%;
      margin: 10px 0px;
      display: flex;
      align-items: flex-start;
      text-align: left;
      position: relative;
      max-width: 300px;
      padding: 0px 20px;
      &.letter {
        margin-bottom: 30px;
      }
      p {
        margin: unset;
        margin-left: 40px;
        font-size: 15px;
        line-height: 15px;
      }
      input[type='checkbox'] {
        position: absolute;
        appearance: none;
        background-color: #000;
        margin: 0;
        font: inherit;
        width: 30px;
        height: 30px;
        border: 1px solid $orange;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        &:before {
          content: '';
          width: 20px;
          height: 20px;
          transform: scale(0);
          border-radius: 2px;
          transition: 120ms transform ease-in-out;
          box-shadow: inset 20px 20px $orange;
        }
        &:checked::before {
          transform: scale(1);
        }
      }
    }
  }
  .infoHolder {
    flex: 0.3;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    max-width: 600px;
    margin: auto;

    pointer-events: none;
    .content {
      width: 100%;
      display: flex;
      padding-top: 80px;
      flex-grow: 1;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .topLogoHolder {
      display: flex;
      width: 100%;
      margin-top: 10px;
      position: relative;
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;
      height: 100px;
      .counter {
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 45px;
        transform: translateX(50%);
        svg {
          font-family: inherit;
          font-size: 2rem;
          font-weight: 900;
          padding-left: 10px;
          width: 150px;
          height: 60px;
        }
        text {
          fill: $orange;
        }
        .outline {
          stroke: white;
          stroke-width: 10px;
          stroke-linejoin: round;
          text-shadow: 4px 4px 5px black;
        }
      }
      .qrlogo {
        max-width: 60%;
      }
    }
  }
  .qrHolder {
    flex: 0.5;
    width: 100%;
    /* justify-content: flex-end; */
    display: flex;
    flex-direction: column;
    position: relative;
    max-width: 600px;
    margin: auto;
    overflow: visible;
    &.done {
      flex: 1 1;
      align-items: center;
      position: absolute;
      justify-content: center;
      height: 100%;
      display: flex;
    }
    .top {
      flex: 0.3;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      position: relative;
    }
    .center {
      flex: 0.8;
      display: flex;
      align-items: center;
      justify-content: center;
      canvas {
        z-index: 1;
        position: absolute;
        pointer-events: none;
        width: 100vw;
        height: 100vh;
        top: unset !important;
        left: unset !important;
      }
    }
    .bott {
      flex: 0.3;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      position: relative;
    }
  }
  h1 {
    margin: auto;
    margin-top: 200px;
    text-align: center;
  }
  .center {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      left: 50%;
      transform: translate3d(-110%, -180%, 0px);
      top: 50%;
      background: url('../assets/qr/scanna.svg');
      background-size: cover;
      background-repeat: no-repeat;
      box-sizing: border-box;
      width: 140px;
      aspect-ratio: 200 / 85;
      z-index: 20;
    }
  }
  .videoHolder {
    //transform:translate3d(-50%,-50%,0);
    border-radius: 50%;
    overflow: hidden;
    width: 250px;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    position: absolute;
    z-index: 10;
    background: black;
    video {
      width: 350px;
      height: 350px;
    }
    &:after {
      content: '';
      position: absolute;
      left: -1px;
      top: -2px;
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      //height: calc(100% + 2px);
      background: url('../assets/scope.svg');
      background-size: cover;
      background-repeat: no-repeat;
      box-sizing: border-box;
    }
  }
}

.coinSwiper {
  width: 100%;
  height: 150px;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0px;

  &._bott {
    transform: scaleY(-1) translateX(-50%);
    bottom: unset;
    top: 0px;
    .swiper-slide {
      .coin {
        transform: scaleY(-1);
        animation-name: rotation2;
        animation-duration: 1s;
        @keyframes rotation2 {
          0% {
            transform: scale(0) scaleY(-1) rotate(0deg) rotateX(0deg);
            opacity: 0;
          }
          100% {
            transform: scale(1) scaleY(-1) rotate(359deg) rotateX(359deg);
            opacity: 1;
          }
        }
      }
    }
  }
  .swiper-slide {
    margin: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: visible !important;
    .coin {
      width: 120px;
      height: 120px;
      border-radius: 100px;
      background: rgba(255, 255, 255, 0.5);
      //box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.2);
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      transition:
        opacity 0.2s ease-in-out,
        transform 0.2s ease-in-out,
        scaleX 0.2s ease-in-out;
      animation-name: rotation;
      animation-duration: 1s;
      @keyframes rotation {
        0% {
          transform: scale(0) rotate(0deg) rotateX(0deg);
          opacity: 0;
        }
        100% {
          transform: scale(1) rotate(359deg) rotateX(359deg);
          opacity: 1;
        }
      }
      backface-visibility: visible;
      img {
        z-index: 200;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: opacity 1s ease-in;
      }
      &.active,
      &.nan {
        &:before {
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          opacity: 0.8;
          background-image: url('../assets/qr/nan.png');
          backface-visibility: visible;
          background-size: cover;
          background-repeat: no-repeat;
        }
      }
      &.active {
        background: rgba(255, 255, 255, 1);

        img {
          opacity: 1;
        }
        &:before {
          opacity: 1;
          opacity: 0;
          backface-visibility: hidden;
        }
      }
    }
  }
}
.Onboarding {
  position: absolute !important;
  left: 50%;
  top: 50%;
  margin: auto;
  width: 300px;
  height: auto;
  transform: translate3d(-50%, -50%, 0);
  .swiper-slide {
    margin: auto;
    width: 350px;
    background: white;
    color: black;
    overflow: visible !important;
    position: relative;

    .slide_content {
      padding: 20px;
      padding-bottom: 0px;
      text-align: center;
      h3 {
        margin: 0;
      }
      p {
        &.small {
          font-size: 0.8rem;
        }
      }
      .qr_tmp {
        margin-top: 10px;
        width: 150px;
        height: 150px;
      }
    }
    -webkit-filter: grayscale(100%) drop-shadow(0px 0px 10px black);
    filter: grayscale(100%) drop-shadow(0px 0px 10px black);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    box-sizing: border-box;
    &.active {
      -webkit-filter: grayscale(0%) drop-shadow(0px 0px 10px black);
      filter: grayscale(0%) drop-shadow(0px 0px 10px black);
      -moz-filter: grayscale(0%);
      -ms-filter: grayscale(0%);
      -o-filter: grayscale(0%);
    }
    &:after,
    &:before {
      content: '';
      width: 100%;
      height: 50px;
      position: absolute;
      display: block;
      background: url('../assets/topbar.svg');
      background-repeat: no-repeat;
      background-position: center bottom;
      background-size: auto;
      top: -49px;
      left: 0px;
      z-index: 50;
    }
    &:after {
      top: unset;
      bottom: -49px;
      transform: scaleY(-1);
    }
    .top_pic {
      width: 101%;
      position: relative;
      margin-top: -33px;
      z-index: 100;
      -webkit-mask-image: url('../assets/topbar_mask.svg');
      mask-image: url('../assets/topbar_mask.svg');
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
    }
  }
  .btn {
    cursor: pointer;
    align-items: center;
    font-weight: 700;
    text-align: center;
    border-radius: 5px;
    color: white;
    background: $orange;
    padding: 10px 50px;
    font-size: 0.8rem;
    width: fit-content;
    margin: auto;
    margin-bottom: 10px;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }
}
.App.Desk {
  .qr .infoHolder .topLogoHolder .counter {
    top: 75px;
  }
}
.swiper-3d .swiper-slide-shadow {
  display: none;
}

.animatedCoin {
  position: absolute;
  pointer-events: none;
  top: -3000px;
  left: calc(50% - 70px);
  width: 140px;
  height: 140px;
  display: block;
  z-index: 999;
  background: url('../assets/qr/neee.png') no-repeat;
  background-size: contain;
}
