$orange: rgb(234 88 12);
$darkblue: #3d405b;
$black: #000;
.cookieOverlay {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  align-items: center;
  justify-content: center;
  .cookiePop {
    box-sizing: border-box;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    max-width: 400px;
    background: white;
    border: 2px solid $orange;
    overflow: hidden;
    position: relative;
    color: white;
    padding: 20px;

    overflow: hidden;
    background: black;
    p {
      text-align: center;
      font-size: 0.8rem;
    }
    .link {
      font-size: 0.8rem;
      text-decoration: underline;
    }
    .btnholder {
      display: flex;
      width: 100%;
      justify-content: space-evenly;

      .cookieBtn {
        cursor: pointer;
        flex: 0.4;
        align-items: center;
        font-weight: 700;
        text-align: center;
        border-radius: 5px;
        border: 2px solid $orange;
        background: rgba(255, 255, 255, 0.2);
        padding: 0.5rem 1.5rem;
        font-size: 0.8rem;
        &.active {
          background: $orange;
          color: white;
        }
      }
    }
    .label {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 1rem;
      line-height: 1rem;
      margin-bottom: 0px;
      img {
        margin-left: 10px;
        margin-top: -10px;
        width: 40px;
      }
    }
  }
  .readMore {
    overflow-y: scroll;
    display: flex;
    width: 100%;
    height: 100vh;
    background: white;
    color: black;
    box-sizing: border-box;
    position: absolute;
    justify-content: center;
    .close {
      position: absolute;
      font-weight: bold;
      right: 20px;
      font-size: 0.8rem;
      top: 50px;
      cursor: pointer;
      &:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 2px;
        background: black;
      }
    }
    .tex {
      max-width: 600px;
      flex-direction: column;
      display: flex;
      padding: 40px;
      padding-top: 80px;
      margin-bottom: 100px;
      .lihead {
        margin-top: 0;
        font-size: 1rem;
        line-height: 1rem;
      }

      .dott_row {
        display: flex;
        margin-bottom: 10px;
        align-items: flex-start;
        justify-content: flex-start;
        .texline {
          margin-top: -8px;
        }
        &.step1 {
          margin-left: 20px;
        }
        &.step2 {
          margin-left: 40px;
        }
        .num {
          margin-right: 10px;
          font-weight: 700;
          font-size: 1rem;
          line-height: 1rem;
        }
        &.small {
          b {
            font-size: inherit;
            line-height: inherit;
          }
          .num {
            font-size: 0.8rem;
            line-height: 1rem;
          }
        }
      }
      a,
      a:hover,
      a:visited {
        color: black;
        font-size: 0.8rem;
      }
      p {
        font-size: 0.8rem;
        box-sizing: border-box;
        margin: 7px 0px;
      }
      b {
        font-size: 0.8rem;
        line-height: 0.8rem;
      }
      .padd {
        display: block;
        width: 100%;

        height: 100px;
        min-height: 100px;
      }
    }
  }
}
