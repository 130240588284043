@import './CookieConcent.scss';

html,
body,
#root,
.App {
  margin: 0;
  font-family:
    Hanken Grotesk,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Open Sans,
    Helvetica Neue,
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0.2);
  background: white;
  font-family: 'Boelve';
  color: white;
  font-size: 18px;
  height: -webkit-fill-available;
  height: -moz-available;
  height: fill-available;
  width: 100vw;
  display: flex;
  position: relative;
}
html {
  overflow: hidden;
}
