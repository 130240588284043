$orange: rgb(234, 88, 12);
$darkblue: #3d405b;
$black: #000;

.start {
  width: 100vw;
  min-height: -webkit-fill-available;
  min-height: -moz-available;
  min-height: fill-available;
  overflow: hidden;
  overflow-y: auto;
  flex: 1;
  background: url('../assets/bg.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  .logoHolder {
    max-width: 400px;
    position: absolute;
    z-index: 300;
    transition: opacity 1s;
    animation-duration: 2s;
    animation-name: slidein;
    top: -40px;
    .logo {
      svg {
        transform: unset !important;
      }
    }
    &.smaller {
      width: 100%;
      max-width: 400px;
      top: -70px;
      left: -5px;
      transform: scale(0.7);
      @media screen and (max-height: 700px) {
        top: -70px;
        left: -10px;
      }
      //transform: translateX(-50%);
    }
    @keyframes slidein {
      from {
        top: 400px;
      }
      to {
        top: -40px;
      }
    }
  }

  .comp {
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: flex-start;
    background: rgba(255, 255, 255, 0.3);
    margin-top: 20px;
    padding: 20px 30px;
    width: 100%;
    &.black {
      background: unset;
      text-align: center;
      align-items: center;
    }
    h2 {
      text-transform: uppercase;
      margin: 0px;
      font-size: 0.9rem;
      line-height: 1.4rem;
      &.center {
        text-align: center;
        align-self: center;
      }
    }
    p {
      margin: 0;
      font-size: 0.8rem;
      line-height: 1rem;
    }
    .inputholder {
      margin-top: 10px;
      width: 100%;
      flex-direction: column;
      display: flex;
      align-items: center;
    }
    input[type='text'] {
      margin: 10px 0px;
      width: 100%;
      color: white;
      font-size: 0.875rem !important;
      line-height: 1.25rem !important;
      padding: 0.75rem 1rem;
      background-color: black;
      border-radius: 0.375rem;
      border: 1px solid $orange;
      max-width: 260px;
      outline: 2px solid transparent;
      &.notvalid {
        color: red;
      }
    }
  }
  .content-holder {
    padding-top: 180px;
    padding-bottom: 100px;
    opacity: 0;
    position: relative;
    max-width: 500px;
    width: 90%;
    transition: opacity 0.5s;
    margin: auto;
    z-index: 10;
    &.show {
      opacity: 1;
    }
    justify-content: center;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .content {
      display: flex;
      align-items: center;
      flex-direction: column;
      background: rgba(0, 0, 0, 0.8);
      padding: 40px 0px;
      border-radius: 5px;
      color: white;
    }
    p {
      -webkit-user-select: none; /* Safari */
      -ms-user-select: none; /* IE 10 and IE 11 */
      user-select: none; /* Standard syntax */
    }
    h1 {
      width: unset;
      margin: unset;
      -webkit-user-select: none; /* Safari */
      -ms-user-select: none; /* IE 10 and IE 11 */
      user-select: none; /* Standard syntax */
    }
  }

  .btn {
    margin-top: 10px;
    cursor: pointer;
    align-items: center;
    font-weight: 700;
    text-align: center;
    border-radius: 5px;
    color: white;
    background: $orange;
    padding: 10px 50px;
    font-size: 0.8rem;
    width: fit-content;

    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }
}
